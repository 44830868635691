import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { Autocomplete } from 'vue2-google-maps';
import { getEnvVar } from '@utils';

const GOOGLE_API_KEY = getEnvVar('GOOGLE_API_KEY'); // Ensure the environment variable is set.

let isGoogleMapsInitialized = false; // Flag to indicate whether Google Maps has been initialized

// Function to initialize Google Maps only once and return a Promise
export default function initializeGoogleMaps() {
  if (!isGoogleMapsInitialized) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: GOOGLE_API_KEY,
        libraries: 'places', // necessary for places input
      },
    });
    Vue.component('gmap-autocomplete', Autocomplete);

    isGoogleMapsInitialized = true; // Set the flag to true after initialization
  }
}
